import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AmenityResponse} from './amenities.component';
import {GetRequestService} from '../../../../service/base/get-reqeust.service';
import {ConsultModalComponent} from "../../../modals/consult-modal/consult-modal.component";
import {DataStateEnum} from "../../../../enum/data-state.enum";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {InfoModalService} from "../../../modals/info-modal/info-modal.service";
import { BaseListResponse } from "../../../../../interface/base-response";
import { environment } from "../../../../../environments/environment";
import { ProductService } from "../../products/product.service";
// import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';

@Injectable({
    providedIn: 'root'
})
export class AmenityService {
   private readonly apiUrl = `${environment.appUrl}/amenities`;

    constructor(
        private getRequestService: GetRequestService,
        private productService: ProductService,
    ) {
    }

    getAmenities(): Observable<BaseListResponse<AmenityResponse>> {
        return this.getRequestService.getAll(this.apiUrl);
    }

    openOrderCall() {
      this.productService.openOrderCall();
    }
}
